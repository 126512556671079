
















































































































































































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { ActionTypes } from '@/store/modules/tools/actions';
import store from '@/store';

export default defineComponent({
  name: 'MSponsorLinkInfo',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    sponsorInfo: {
      type: Object,
      default: () => {},
      required: false
    }
  },
  setup(props: any, ctx: any) {
    const dialog = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const closeDialog = () => {
      localStorage.setItem(`sponsorInfo`, JSON.stringify(props?.sponsorInfo));
      dialog.value = false;
      store.dispatch(`tools/${ActionTypes.showBalloonPopup}`);
    };

    const showCopied = ref(false);

    const copySponsorLink = () => {
      if (props.sponsorInfo?.sponsorLink) {
        navigator.clipboard
          .writeText(props.sponsorInfo.sponsorLink)
          .then(() => {
            showCopied.value = true;
            setTimeout(() => {
              showCopied.value = false;
            }, 2000);
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
          });
      }
    };

    return {
      dialog,
      closeDialog,
      copySponsorLink,
      showCopied
    };
  }
});
